import React from 'react';
import AccountButton from './components/AccountButton';
import Header from './components/Header';
import Footer from './components/Footer';
import DentedScribble from './assets/images/DentedFeels_Scribble.svg';
import './styles/globals.css';
import { Toaster } from 'react-hot-toast';
import GetTokenInfo from './components/GetTokenInfo';
import GirthPointsCalculator from './components/GirthPointsCalculator';


function App() {
  return (
    <div className="App">
      <Toaster toastOptions={{ className: '', style: { border: '3px solid #000', padding: '5px', color: '#000' } }} />
      <div>
        <Header />
        <div className="df-section">
          <div className="df-overfow">
            <div className="df-container">
              <div className="df-hero">
                <div></div>
                <div className="df-wrapper-padding">
                  <h1 className="df-header">Hey z00tz, check out muh girth</h1>
                  <p className="df-text">This is where your girth happens</p>
                  <div></div>
                  <AccountButton />
                  <GetTokenInfo />
                  <GirthPointsCalculator />
                  <div
                    className="df-divider"
                    style={{ position: 'relative', width: '85%', height: '20px' }}
                  >
                    <img style={{ width: '85%' }} src={DentedScribble} alt="" />
                  </div>                  <div
                    className="df-divider"
                    style={{ position: 'relative', width: '85%', height: '20px', userSelect: 'none' }}
                  >
                    <img style={{ width: '85%' }} src={DentedScribble} alt="" />
                  </div>
                  <div className="modules-container">
                  <div className="row">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
