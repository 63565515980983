import { Contract } from '@ethersproject/contracts';
import React, { useEffect, useState } from 'react';
import z00tzAnchorClubAbi from '../abi/zacabi.json'; // Make sure this ABI matches your contract
import { Web3Provider } from '@ethersproject/providers';
import { useEthers } from '@usedapp/core';

const GetUserTokenInfo = () => {
    const { account } = useEthers();
    const z00tzAnchorClubAddress = '0x270edbe355ba17f787503c372464ecbaf3ada61a'; 
  
    const provider = new Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  const z00tzAnchorClubContract = new Contract(z00tzAnchorClubAddress, z00tzAnchorClubAbi, signer);

 
  const [userTokenCount, setUserTokenCount] = useState<number>(0);
  const [error, setError] = useState<string>('');

  const fetchUserTokenIDs = async () => {
    try {
      const balance = await z00tzAnchorClubContract.balanceOf(account);
      setUserTokenCount(balance.toNumber());
    } catch (error: any) {
      setError(`Failed to fetch user's token IDs: ${error.message}`);
    }
  };

  useEffect(() => {
    if (account) {
      fetchUserTokenIDs();
    }
  }, [account]);

  return (
    <div>
      <h2>Total Token IDs Held by You:</h2>
      <p>{userTokenCount}</p>
      {error && <p>Error: {error}</p>}
    </div>
  );
};

export default GetUserTokenInfo;