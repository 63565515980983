import React, { useEffect } from 'react';
import { useEthers, shortenAddress, useLookupAddress } from '@usedapp/core';
import '../styles/globals.css';

export const AccountButton = () => {
  const { account, deactivate, activateBrowserWallet } = useEthers()
  const { ens } = useLookupAddress(account)

  const activate = async () => {
    await activateBrowserWallet();
  };

  const confirmDeactivate = () => {
    if (window.confirm('Are you sure you want to disconnect your wallet?')) {
      deactivate();
    }
  };

  useEffect(() => {
    // Listen for changes to the account variable
  }, [account]);

  return (
    <div>
      {account ? (
        <div>
          <div className="df-connect-btn" onClick={confirmDeactivate}>
            {ens ? ens : shortenAddress(account)}
          </div>
        </div>
      ) : (
        <div className="df-connect-btn" onClick={activate}>Connect</div>
      )}
    </div>
  );
}
export default AccountButton;