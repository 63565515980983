import React, { useState, useEffect } from 'react';
import { useEthers } from '@usedapp/core';
import z00tzAnchorClubAbi from '../abi/zacabi.json'; // Make sure this ABI matches your contract
import { Contract } from '@ethersproject/contracts';
import { Web3Provider } from '@ethersproject/providers';

const GirthPointsCalculator = () => {
    const z00tzAnchorClubAddress = '0x270edbe355ba17f787503c372464ecbaf3ada61a'; 
  
    const provider = new Web3Provider(window.ethereum);
    const signer = provider.getSigner();
  
    const z00tzAnchorClubContract = new Contract(z00tzAnchorClubAddress, z00tzAnchorClubAbi, signer);
    const { account } = useEthers();
    const [balance, setBalance] = useState<number>(0);
    const [girthPoints, setGirthPoints] = useState<number>(0);
    const [error, setError] = useState<string | null>(null);
  
    useEffect(() => {
      const fetchBalance = async () => {
        if (account) {
          try {
            const balance = await z00tzAnchorClubContract.balanceOf(account);
            setBalance(balance.toNumber());
          } catch (error: any) {
            setError(`Failed to fetch user's balance: ${error.message}`);
          }
        }
      };
  
      fetchBalance();
    }, [account]);
  
    useEffect(() => {
      // Define the base date for the calculation
      const baseDate = new Date('2023-11-03'); // Replace with your actual base date
      const currentDate = new Date();
      const daysHeld = Math.floor((currentDate.getTime() - baseDate.getTime()) / (1000 * 60 * 60 * 24));
  
      // Calculate girth points
      const totalGirthPoints = 100 + balance * daysHeld; // 100 base points + 1 point per day per token
      setGirthPoints(totalGirthPoints);
    }, [balance]);
  
    return (
      <div>
        {error && <p className="error">{error}</p>}
        <h2>Girth Points:</h2>
        <p>{girthPoints}</p>
        {/* Render token columns or other UI elements here */}
      </div>
    );
  };
  
  export default GirthPointsCalculator;