import React from 'react'

const Footer = () => (
  <>
    <div className="df-footer">
    <div className="df-footer-wrapper">
      <div className="df-footer-container">
        <div className="df-ticker">
          <div className="df-ticker-wrapper">
          <div className="df-header-ticker">z00tz are anchored with Maximum !girth</div><img width={"60px"} height={"60px"} objectFit="contain" src="/images/DentedFeels_Spinner.png" alt="logo" loading="lazy" className="df-ticker-img"/>
          </div>
        </div>
        <div className="df-footer-content">
          <div className="df-main-grid">
            <div className="df-content-wrapper">
              <div className="df-subhead df-grey">z00tz Anchor Club 2023. All rights reserved.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </>
)

export default Footer