import React from 'react'
import ReactDOM from 'react-dom'
import type { ChainId } from '@usedapp/core';
import { DAppProvider, Goerli, Mainnet } from '@usedapp/core'
import App from './App'

const config = {
  readOnlyChainId: Goerli.chainId,
  readOnlyUrls: {
   // [Mainnet.chainId]: process.env.REACT_APP_MAINNET as string,
    [Goerli.chainId]: "https://goerli.infura.io/v3/c294105c76c644a08c0a47d7531d4fc1",
  },
}

ReactDOM.render(
  <React.StrictMode>
    <DAppProvider config={config}>
      <App />
    </DAppProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
